/** @jsx jsx */
import React from 'react'
import Disclaimer from '../../shared/Disclaimer'
import { Box, jsx } from 'theme-ui'
import {
  disclaimerOptions,
  descriptionOptions,
  convertRichText,
} from '../../../utils/rich-text'

export default function AgencyPageRenderer({ description, disclaimer }) {
  let descriptionConverted
  if (description?.raw) {
    descriptionConverted = convertRichText(description, descriptionOptions)
  }

  let disclaimerConverted
  if (disclaimer?.raw) {
    disclaimerConverted = convertRichText(
      disclaimer.disclaimer,
      disclaimerOptions
    )
  }

  return (
    <div
      sx={{
        position: 'relative',
        minWidth: '100%',
        padding: ['0 10px', '0 90px'],
      }}
    >
      <div sx={{ height: '100%' }}>
        <div sx={{ height: '100%' }}>
          <Box
            sx={{
              overflow: 'auto',
              padding: '15px',
            }}
          >
            {descriptionConverted}
          </Box>
        </div>
        {disclaimerConverted && (
          <div
            sx={{
              position: 'absolute',
              bottom: '0',
              left: '0',
              width: '100%',
              padding: ['0', '0 10%'],
              height: '10%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Disclaimer
              disclaimer={disclaimerConverted}
              styles={{ margin: '0', overflow: 'auto', maxHeight: '100%' }}
            />
          </div>
        )}
      </div>
      {disclaimerConverted && (
        <div
          sx={{
            position: 'absolute',
            bottom: '0',
            left: '0',
            width: '100%',
            padding: ['0', '0 10%'],
            height: '10%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Disclaimer
            disclaimer={disclaimerConverted}
            styles={{ margin: '0', overflow: 'auto', maxHeight: '100%' }}
          />
        </div>
      )}
    </div>
  )
}
