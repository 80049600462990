/** @jsx jsx */
import { jsx } from 'theme-ui'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Container from '../components/Container'

import SEO from '../components/SEO'
import Header from '../components/Header'

import { uniq } from 'lodash'
import AgencyPageRenderer from '../components/main/AgencyPages/AgencyPageRenderer'

const PageTemplate = ({ data }) => {
  const { title, beaches, bannerImage, metaDescription } = data.contentfulRule
  const beachesList = beaches?.map((beach) => beach.name)
  const agencies = uniq(
    beaches
      ?.filter((beach) => beach.agencyName !== null)
      ?.map((beach) => beach.agencyName)
  )

  return (
    <Layout>
      <SEO
        title={title}
        description={metaDescription ? `${metaDescription}` : title}
      />
      <Header
        hideSearch
        headerText={title}
        image={bannerImage?.gatsbyImageData}
        imageAltText={bannerImage?.description || bannerImage?.title}
        imageCred={bannerImage?.description}
        height={'30vh'}
        alert={{
          text: beachesList?.join(', '),
        }}
        subHeader={
          agencies.length > 0 ? `written by ${agencies?.join(', ')}` : null
        }
      />
      <Container mw sx={{ minHeight: '35vh' }}>
        <AgencyPageRenderer hideTitle {...data.contentfulRule} />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query ($id: String!) {
    contentfulRule(contentful_id: { eq: $id }) {
      id
      title

      beaches {
        name
        agencyName
      }
      icon {
        file {
          url
        }
      }
      disclaimer {
        ... on ContentfulRuleDisclaimer {
          raw
        }
      }
      metaDescription
      description {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      bannerImage {
        gatsbyImageData(layout: FULL_WIDTH, width: 2400)
        description
        title
      }
    }
  }
`

export default PageTemplate
