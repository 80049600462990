/** @jsx jsx */
import { jsx } from 'theme-ui'

export default function Disclaimer(props) {
  const { styles } = props
  return (
    <div
      sx={{
        backgroundColor: 'disclaimer',
        padding: '10px',
        margin: '10px auto',
        borderRadius: '10px',
        textAlign: 'left',
        ...styles,
      }}
    >
      <div sx={{ variant: 'styles.disclaimer' }}>
        <strong>Disclaimer: </strong>
        {props.disclaimer}
      </div>
    </div>
  )
}
